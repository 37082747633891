import "./styles/main.scss";
import TeamBios from './scripts/block-scripts/TeamBios';
import CounterGutenplate from './scripts/block-scripts/CounterGutenplate';

/**
* Do all the things you would normally do in Javascript after DOM is loaded
*/
window.addEventListener('load', (e) => {

  var input22 = document.getElementById("input_2_22");
  if (input22) {
    document.getElementById("input_2_22").value = "1st date";
  }

  var input23 = document.getElementById("input_2_23");
  if (input23) {
    document.getElementById("input_2_23").value = "2nd date";
  }

  // initialize slide on load
  var elms = document.getElementsByClassName( 'splide' );
    for ( var i = 0, len = elms.length; i < len; i++ ) {
    new Splide( elms[ i ] ).mount();
  }

  // Team Bios
  if (document.getElementById('teamBios')) {
    const tb = new TeamBios();
    tb.setBioLinkEvents();
  }
  
  if (document.getElementById('vendor-container')) {
      var containerEl = document.querySelector('#vendor-container');
      var mixer;
      if (containerEl) {
          mixer = mixitup(containerEl, {         
            multifilter: {
              enable: true
            },
            animation: {
              "duration": 700,
              "nudge": true,
              "reverseOut": false,
              "effects": "fade translateX(-100%) translateZ(26px) stagger(30ms)"
            },
            classNames: {
                block: 'programs',
                elementFilter: 'filter-btn',
                elementSort: 'sort-btn'
            },
            selectors: {
                target: '.mix-target'
            }
          });
      }
  }

  if (jQuery(".standard-card-feed")[0]){
    var mixer = mixitup('.mix-container');

    if (jQuery('.mix-container')) {
      var containerEl = jQuery('.mix-container')
      var mixer = mixitup(containerEl, {
        multifilter: {
          enable: false
        },
        animation: {
            effects: 'fade translateZ(-100px)'
        },
        callbacks: {
            onMixStart: function(state, futureState) {
          },
          onMixEnd: function() {
            containerEl
            .find('.card:visible:first')
            .focus();
          }
        }
      });
      if (location.hash) {
        var hash = location.hash.replace('#', '.')
        var oldBtn = jQuery('button.mixitup-control-active');
        var newBtn = jQuery("button").find("[data-filter='" + hash + "']");
        mixer.filter(hash)
        oldBtn.removeClass('mixitup-control-active');
        newBtn.addClass('mixitup-control-active');
      }
    }
  }

  /*Dropdown Menu*/
  $('.menu-btn-dropdown').click(function () {
    $(this).attr('tabindex', 1).focus();
    $(this).toggleClass('active');
    $(this).find('.dropdown-menu').slideToggle(300);
  });
  $('.menu-btn-dropdown').focusout(function () {
    $(this).removeClass('active');
    $(this).find('.dropdown-menu').slideUp(300);
  });
  $('.menu-btn-dropdown .dropdown-menu li').click(function () {
    $(this).parents('.menu-btn-dropdown').find('span').text($(this).text());
    $(this).parents('.menu-btn-dropdown').find('input').attr('value', $(this).attr('id'));
  });
 
  // counter functions
  if (document.getElementsByClassName("gutenplateCounterClass")) {
    const hc = new CounterGutenplate();
    const gutenplateCounterObject = document.getElementsByClassName("gutenplateCounterClass");
    var arrayitemid = [];
    
    jQuery(window).scroll(function() {
      for (let item of gutenplateCounterObject) {
        var top_of_element = jQuery("#" + item.id).offset().top;
        var bottom_of_element = jQuery("#" + item.id).offset().top + jQuery("#" + item.id).outerHeight();
        var bottom_of_screen = jQuery(window).scrollTop() + jQuery(window).innerHeight();
        var top_of_screen = jQuery(window).scrollTop();
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){  
          hc.onlyCallOnce(item, arrayitemid);
        }
      }
    });
  }

  // pasta drop link menu
  
  
  

  var scrollID = document.getElementById("menu-jump-link-nav");
  
  if (scrollID) {
    var jumpLinkLocation = $('#menu-jump-link-nav').offset();
  }
  
    
  $(window).on("load resize scroll", function () {
    if (scrollID) {
      if ($(this).scrollTop() >= (jumpLinkLocation.top - 100)) {
        $('#menu-jump-link-nav').addClass("jump-menu-sticky");
      } else {
        $('#menu-jump-link-nav').removeClass("jump-menu-sticky");
      }
    }
  })

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    $(window).on("load", function () {
      // add show class on mobile
      if ($(this).width() >= 977) {
        $('.accordion-collapse').addClass("show");
        $('.accordion-button').removeClass("collapsed");
      } else {
        $('.accordion-collapse').removeClass("show");
        $('.accordion-button').addClass("collapsed");
      }
    }) 
  }else{
    $(window).on("load resize", function () {
      // add show class on mobile
      if ($(this).width() >= 977) {
        $('.accordion-collapse').addClass("show");
        $('.accordion-button').removeClass("collapsed");
      } else {
        $('.accordion-collapse').removeClass("show");
        $('.accordion-button').addClass("collapsed");
      }
    }) 
  }
});

// inject zipcode into map
if (document.title == 'Locations - Oregano\'s') {
  const queryString = window.location.search;
  // console.log(queryString);
  const urlParams = new URLSearchParams(queryString);
  // console.log(urlParams);
  const zipCode = urlParams.get('zipCode')
  document.getElementById('wpsl-search-input').value = zipCode;
  // console.log(zipCode);

  // wait for elements to load
  function waitForElmZip(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  waitForElmZip('.wpsl-store-location').then(() => {
    if (zipCode) {
      // console.log('exists');
      // setTimeout(function(){ 
        var searchButton = document.getElementById('wpsl-search-btn');
        searchButton.click();
      // }, 3000); 
    }
  });
}