export default class TeamBios {
    onlyCallOnce = (item, arrayitemid) => {
        if (!arrayitemid.includes(item.id)) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                    const progress = Math.min((timestamp - startTimestamp) / item.dataset.counter, 1);
                    let numberReducer = Math.floor(progress * (item.dataset.target - 0) + 0);
                    item.innerHTML = numberReducer.toLocaleString("en-US");
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
            arrayitemid.push(item.id);
        }
    };
}

