import { registerListener } from '../helpers/helpers';

export default class TeamBios {
  constructor() {
    this.teamBios = document.querySelector('.teamBios');
  }

  /**
   * Set click events for each of the readBio buttons and close buttons
   */
  setBioLinkEvents() {
    const readBioLinks = document.querySelectorAll('.readBioBtn');
    const closeBioLinks = document.querySelectorAll('.closeBio');

    readBioLinks.forEach((readLink) => {
      registerListener(readLink, 'click', (e) => this.showBio(e, readLink.dataset.bio));
    })

    closeBioLinks.forEach((closeLink) => {
      registerListener(closeLink, 'click', this.hideAllBios);
    })
  }

  /**
   * Show the bio
   *
   * @param e
   * @param bioClassname
   */
  showBio(e, bioClassname) {
    e.preventDefault();
    this.currentPerson = document.querySelector('.' + bioClassname);
    if (this.currentPerson.classList.contains('showBio')) {
      this.hideAllBios();
      // Do nothing else, just going to keep all bios hidden
    } else {
      // Hide all Bios first
      this.hideAllBios();

      // Updated CSS variant (--bio-height) to the height of the bio we are going to show
      this.bioToShow = document.querySelector('.' + bioClassname + ' .personsBio');
      this.bioHeight = this.bioToShow.offsetHeight + 10;
      
      if (!teamBios.length) {
        this.teamBios.style.setProperty('--bio-height', this.bioHeight + 'px');
      } else {
        for (var i = 0, l = teamBios.length; i < l; i++) {
          if (teamBios[i].getElementsByClassName(bioClassname).length > 0 ) {
            teamBios[i].style.setProperty('--bio-height', this.bioHeight + 'px');
          }
        }
      }

      // Show the bio
      this.currentPerson.classList.add('showBio');
    }
  }

  /**
   * Hides all Bios
   */
  hideAllBios() {
    document.querySelectorAll('.person').forEach((person) => {
      person.classList.remove('showBio');
    })
  }
}
