/* eslint import/prefer-default-export: 0 */

/**
 * Check last part of URL for a string
 *
 * @param string
 * @param checkAllParts
 * @returns {boolean}
 */
 const checkUrlFor = (string, checkAllParts = false) => {
  const urlLoc = window.location.href.substr(window.location.href.length - 1) === '/'
    ? window.location.href.substr(0, window.location.href.length - 1)
    : window.location.href;
  const urlArray = urlLoc.split('/');

  if (checkAllParts) {
    return urlArray.includes(string);
  }

  return urlArray[urlArray.length - 1] === string;
};

/**
 * Shorten a supplied text to a specific length
 *
 * @param text
 * @param maxLength
 * @param ending
 * @returns {string}
 */
const shorterText = (text, maxLength, ending = '...') => {
  if (typeof text === 'string') {
    return text.length > maxLength ? text.substring(0, maxLength) + ending : text;
  }

  return 'Invalid "text" value: Supplied value must be a string';
};

/**
 * Returns a bare object of the URL's query parameters.
 * You can pass just a query string rather than a complete URL.
 * The default URL is the current page.
 *
 * @param url
 */
function getUrlParams(url) {
  let tempUrl = url;

  if (typeof url === 'undefined') {
    tempUrl = window.location.search;
  }

  [tempUrl] = tempUrl.split('#'); // Discard fragment identifier
  const urlParams = {};
  let [, queryString] = tempUrl.split('?');

  if (!queryString) {
    if (tempUrl.search('=') !== false) {
      queryString = tempUrl;
    }
  }

  if (queryString) {
    const keyValuePairs = queryString.split('&');
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      const keyValuePair = keyValuePairs[i].split('=');
      const paramName = keyValuePair[0];
      const paramValue = keyValuePair[1] || '';
      urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
    }
  }

  return urlParams;
}

/**
 * Register event listener.
 *
 * NOTE: window.attachEvent is used for IE < 9. So if using "this" within
 * the event, it will reference the window object and not the function.
 *
 * @param element
 * @param event
 * @param func
 * @param options
 */
const registerListener = (element, event, func, options) => {
  element.addEventListener ? element.addEventListener(event, func, options) : element.attachEvent(`on${event}`, func);
};

/**
 * Remove event listener.
 *
 * @param element
 * @param event
 * @param func
 * @param options
 */
const removeListener = (element, event, func, options) => {
  element.removeEventListener ? element.removeEventListener(event, func, options) : element.detachEvent(`on${event}`, func);
};

/**
 * Check if we are using IE
 *
 * @returns {boolean}
 */
const isIE = () => document.documentMode;

/**
 * Check if we are using EDGE
 *
 * @returns {boolean}
 */
const isEdge = () => /Edge/.test(navigator.userAgent);

/**
 * Check to see if an element is in the viewport
 *
 * @param el
 * @returns {boolean}
 */
const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0
    && rect.right >= 0
    && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * Calculate the distance between 2 points
 *
 * lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)
 * lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)
 * unit = the unit you desire for results
 *        where: 'M' is statute miles (default)
 *               'K' is kilometers
 *               'N' is nautical miles
 *
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @param unit
 * @returns {number}
 */
const distanceBetweenPoints = (lat1, lon1, lat2, lon2, unit) => {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }

  const radlat1 = Math.PI * lat1 / 180;
  const radlat2 = Math.PI * lat2 / 180;
  const theta = lon1 - lon2;
  const radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') { dist *= 1.609344; }
  if (unit === 'N') { dist *= 0.8684; }
  return dist;
};

/**
 * Check a value to see if is a float
 *
 * @param inputVal
 * @returns {boolean}
 */
const isDecimal = (inputVal) => {
  const decimalRegex = /^[-+]?[0-9]+\.[0-9]+$/;

  return inputVal.value.match(decimalRegex);
};

export {
  checkUrlFor,
  shorterText,
  getUrlParams,
  registerListener,
  removeListener,
  isIE,
  isEdge,
  isInViewport,
  distanceBetweenPoints,
  isDecimal,
};